(function() {
  var images = document.querySelectorAll('img[data-src]');
  var i;
  var observer;

  function onIntersection(entries) {
    // Loop through the entries
    entries.forEach(function(entry) {
      // Are we in viewport?
      if (entry.intersectionRatio > 0) {

        // Stop watching and load the image
        observer.unobserve(entry.target);
        lazyLoadImage(entry.target);
      }
    });
  }

  var observer;

  if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver(onIntersection, {
      rootMargin: '50px 0px',
      threshold: 0.01,
    });
  }

  function setUpLazyLoading(image) {
    if (observer) {
      observer.observe(image);
    } else {
      lazyLoadImage(image);
    }
  }

  function lazyLoadImage(image) {
    image.src = image.getAttribute('data-src');
  }

  for (i = 0; i < images.length; i++) {
    setUpLazyLoading(images[i]);
  }
})();
